@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li a {
    padding: 30px 10px;
  }
  .is-sticky .main-menu ul li a {
    padding: 20px 15px;
  }

  .main-menu ul li:hover ul {
    top: 86px;
  }
  .single-post-text ul {
    padding: 0px;
  }
  .single-post-text li a {
    padding: 10px 5px;
  }
  .all-testimonial .owl-controls {
    bottom: -15px;
  }
  .sngl-client {
    margin: 10px;
  }
  .sec-title {
    margin: 0 100px 60px;
  }
  .slider-text {
    width: 870px;
  }
  .info-intro {
    margin-left: 8px;
  }
  .counting-inner {
    display: inline-block;
    padding-right: 40px;
  }
  .simplefilter li {
    padding: 10px 18px;
  }
  .sec-title h1 {
    font-size: 36px;
  }
  .appointment-form::after {
    bottom: -170px;
    right: -120px;
  }
  .search-forum {
    top: 86px;
  }
  .hd-style1 .hd-rgt::before {
    left: 0;
  }
  .post-date li {
    margin: 0px 2px;
  }
}
@media only screen and (max-width: 1023px) {
  .responsive-nav-menu {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .menu {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  body p {
    font-size: 14px;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  .mnmenu-sec {
    background: #fff;
  }
  .hd-style1 .hd-rgt {
    text-align: right;
  }
  .service-item .inner h2 {
    font-size: 16px;
  }
  .sec-title h1 {
    font-size: 32px;
  }
  .sec-title {
    margin: 0 0 60px;
  }
  .counting_sl {
    margin: 40px 0;
  }

  .faq-img {
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 25px;
  }
  .special-service-sec .inner:nth-child(2n + 1) {
    clear: both;
  }
  .all-testimonial .owl-controls {
    bottom: -10px;
  }

  .client-comment li a {
    margin: 0 2px;
  }
  .footer-sec .col-md-3.col-sm-6 {
    margin-bottom: 25px;
    width: 100%;
    display: inline-block;
  }
  .slider-text,
  .slider-wraper {
    width: 100%;
  }
  .all-slide .owl-controls .owl-prev i,
  .all-slide .owl-controls .owl-next i {
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  .about img {
    margin-bottom: 30px;
  }

  .footer-widget-menu {
    margin-bottom: 40px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .pt-50 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  .pb-50 {
    padding-bottom: 30px;
  }
  .why-choose-item {
    margin: 40px 0;
  }
  .slider-text {
    padding: 84px;
  }
  .about-us-img {
    text-align: center;
  }
  .appointment-form::before {
    left: -15px;
    transform: skewX(0deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body p {
    font-size: 14px;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .pt-50 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  .pb-50 {
    padding-bottom: 30px;
  }
  .about-us-img::before {
    display: none;
  }
  .sidebar {
    margin-top: 40px;
  }
  .hd-style1 .main-menu ul li::after {
    display: none;
  }
  .about-us-img img {
    width: 100%;
    margin-top: 30px;
  }
  .project-sec .sec-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .simplefilter {
    text-align: center;
  }
  .all-slide .owl-controls {
    right: 30px;
  }
  .testimonial-text .sec-title {
    text-align: left;
  }
  .why-choose.why-choos-bg {
    background-image: none;
  }
  .sngl-price {
    margin: 30px 0;
  }
  .flicker-photo ul li img {
    width: 30%;
  }

  .count-up-sec .inner::before {
    display: none;
  }
  .service-item .inner h2 {
    font-size: 16px;
  }
  .team-member .media-body {
    width: 100%;
    float: left;
    text-align: center;
  }
  .team-thumb {
    float: none;
    width: 100%;
  }
  .team-member .media-left {
    float: none;
    padding-right: 0;
    width: 100%;
  }
  .sec-title h1 {
    font-size: 32px;
  }
  .main-menu ul li a {
    padding: 30px 10px;
  }
  .main-menu ul li:hover ul {
    top: 86px;
  }
  .search-forum {
    top: 86px;
  }
  .menu-icon {
    padding: 32px 0px;
  }
  .why-choose-item {
    margin: 20px 0;
  }
  .appointment-form::after {
    bottom: -173px;
    right: -176px;
  }
  .sec-title {
    margin: 0 0 60px;
  }
  .counting_sl {
    margin: 40px 0;
  }

  .client-comment li a {
    margin: 0 2px;
  }
  .footer-sec .col-md-3.col-sm-6 {
    margin-bottom: 25px;
  }
  .contact-person {
    padding: 0px 15px;
  }

  .slider-text,
  .slider-wraper {
    width: 100%;
  }
  .slider-text {
    padding: 84px;
  }
  .about-us-img {
    text-align: center;
  }
  .appointment-form::before {
    left: -15px;
    transform: skewX(0deg);
    height: 2000px;
  }
  .call-to-action-img img {
    margin-top: 0px;
  }

  .all-slide .owl-controls .owl-prev i,
  .all-slide .owl-controls .owl-next i {
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  .footer-widget-menu {
    margin-bottom: 40px;
  }

  .about img {
    margin-bottom: 30px;
  }
  .slider-text p {
    padding: 0 0px;
  }
  .hd-lft li {
    padding: 0 4px;
  }
  .hd-lft li::before {
    display: none;
  }
  .footer-sec .col-md-3.col-sm-6:nth-child(2n + 1) {
    clear: both;
  }
  .hd-rgt li {
    margin-right: 1px;
  }
  .info-intro i {
    font-size: 28px;
    margin-right: 4px;
  }
  .info-intro {
    margin-left: 0px;
  }
  .info-text h5 {
    font-size: 14px;
  }
  .main-menu ul {
    float: none;
    text-align: center;
  }
  .map-heading {
    margin-top: 40px;
  }
  .is-sticky .main-menu ul li a {
    padding: 20px 15px;
  }

  .footer-sec {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 470px) {
  .hd-lft li::before {
    display: none;
  }
  .hd-lft li {
    padding: 0px 6px;
  }
  .hd-lft li i {
    margin-right: 4px;
  }
  .footer-sec .col-md-4.col-sm-6 {
    margin-bottom: 30px;
  }
  .footer-sec {
    padding-bottom: 0;
  }
  .team-member .media-body {
    width: 100%;
    float: left;
    text-align: center;
  }
  .team-thumb {
    float: none;
    width: 100%;
  }
  .team-member .media-left {
    float: none;
    padding-right: 0;
    width: 100%;
  }
  .hd-sec .container {
    padding: 0;
  }
  .slider-text {
    padding: 0;
  }
  .slider-text p {
    color: #fff;
    padding: 0 100px;
  }
  .slider-text li:last-child a {
    margin-top: 15px;
  }
  .all-slide .owl-item {
    height: 600px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  body p {
    font-size: 14px;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  .hd-style1 .hd-sec {
    display: none;
  }
  .all-slide .single-slide {
    height: 400px;
  }
  .all-slide .slick-dots {
    display: none !important;
  }
  .mnmenu-sec.sticky_menu {
    position: relative;
  }
  .hd-style1 .hd-rgt {
    text-align: center;
  }
  .menu-icon {
    display: none;
  }
  .about-us-img::before {
    display: none;
  }
  .sidebar {
    margin-top: 40px;
  }
  .about-us-img img {
    width: 100%;
    margin-top: 30px;
  }
  .project-sec .sec-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .simplefilter {
    text-align: center;
  }
  .all-slide .owl-controls {
    right: 30px;
  }
  .testimonial-text .sec-title {
    text-align: left;
  }
  .why-choose.why-choos-bg {
    background-image: none;
  }
  .sngl-price {
    margin: 30px 0;
  }
  .flicker-photo ul li img {
    width: 30%;
  }
  .hd-style1 .hd-rgt::before {
    left: -100px;
    transform: none;
    width: 2000px;
  }
  .hd-style1 .hd-rgt ul {
    text-align: center;
  }
  .service-item .inner h2 {
    font-size: 16px;
  }
  .appointment-form {
    margin-top: 50px;
  }
  .sec-title h1 {
    font-size: 32px;
  }
  .hd-lft {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .hd-rgt {
    float: none;
    text-align: center;
  }
  .mean-container .mean-nav ul li a i {
    display: none;
  }
  .contact-person {
    padding: 0px 15px;
  }

  .slider-text {
    padding: 0 30px;
  }

  .slider-text p {
    padding: 0;
  }
  .newsletter-left {
    text-align: center;
  }
  .newsletter-right {
    float: none;
    text-align: center;
    margin-top: 25px;
  }

  .footer-wedget-one {
    margin-bottom: 30px;
  }
  .simplefilter {
    margin-top: 40px;
  }
  .shorttitle {
    display: block;
  }
  #navbarfiltr.navbar-collapse {
    border: 0;
    box-shadow: none;
  }
  .navbarsort .navbar-toggle {
    background: #fff;
    margin-top: 0;
  }
  .navbarsort .navbar-toggle .icon-bar {
    background: #000;
  }
  .shorttitle h2 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
    display: inline-block;
    margin-left: 10px;
  }
  #navbar li {
    display: inline-block;
    width: 100%;
  }
  #navbar ul li a {
    padding: 6px 9px;
  }

  .simplefilter li {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .navbarsort {
    display: block;
  }
  .call-to-action-text {
    text-align: center;
    margin-bottom: 80px;
  }
  .call-to-action-img {
    text-align: center;
  }
  .count-up-sec .inner::before {
    display: none;
  }
  .call-to-action-img img {
    margin: 0px auto;
    float: none;
    text-align: center;
  }
  .call-to-action-text a.btn {
    float: none;
    margin-top: 20px;
  }
  .contact-intro {
    margin-top: 15px;
    text-align: center;
  }
  .info-intro {
    float: none;
    margin: 0px 5px;
  }
  .info-intro i {
    font-size: 28px;
    margin-right: 4px;
  }
  .info-text h5 {
    font-size: 14px;
    text-align: left;
  }
  .page-heading h1 {
    text-align: center;
    margin-bottom: 15px;
  }
  .page-heading ul {
    text-align: center;
  }
  .abt-img img {
    margin-bottom: 20px;
  }
  .map-heading {
    margin-top: 40px;
  }
  .slider-text {
    padding: 0 30px;
    text-align: center;
  }
  .sticky-wrapper {
    background: #292929 none repeat scroll 0 0;
    overflow: hidden;
    padding-top: 0;
    position: relative;
  }
  .project-gallery {
    background: #e0a400;
    padding: 30px 20px;
  }

  .slider-text h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .pagehding-sec {
    padding: 50px 0;
  }
  .company-openingtime {
    margin: 0px auto;
  }
}

@media only screen and (min-width: 0px) and (max-width: 670px) {
  .counting-inner {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 520px) {
  .slider-text {
    top: 50%;
  }
  .all-slide .single-slide {
    height: 500px;
  }
  .hd-sec {
    display: inline-block;
    position: relative;
    background: #292929;
    width: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 380px) {
  .hd-rgt {
    float: none;
    text-align: center;
    margin-bottom: 8px;
  }
  .hd-lft li {
    padding: 5px 0;
    width: 100%;
  }
  .slider-text {
    padding: 0 20px;
  }
  .all-patner.owl-carousel.owl-theme .owl-item {
    padding: 0 6px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 359px) {
  .slider-text {
    padding: 0 20px;
    text-align: center;
    top: 80%;
  }
  .all-slide .owl-item {
    height: 900px;
  }
  .all-slide .single-slide {
    height: 600px;
  }
}
