@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #06931b;
  --color-primary-light: #6abe76;
  --color-primary-dark: #045810;
  --color-primary-darker: #023b0b;
  --color-primary-darkest: #011d05;

  --color-secondary: #1158bb;
  --color-secondary-light: #709bd6;
  --color-secondary-dark: #0a3570;
  --color-secondary-darker: #07234b;
  --color-secondary-darkest: #031225;
}

body {
  margin: 0;
  @apply bg-background;
}
